<template>
  <div class="">
    <DashboardNavbar />
    <PreLoader v-if="isLoading" />

    <div class="app-content">
      <div class="reports-cards">
        <div
          v-if="checkPrivilege(hasStudentsIdReport())"
          class="reports-card animate__animated animate__bounceInRight"
          @click="openBottomSheet('StudentsIdReportFilter')"
        >
          <div class="flex">
            <img src="@/assets/images/cards.svg" />
            <h4>{{ $t("Reports.studentsId") }}</h4>
          </div>
        </div>

        <div
          v-if="checkPrivilege(hasStudentsWalletReport())"
          class="reports-card animate__animated animate__bounceInUp"
          @click="openBottomSheet('StudentsWalletReportFilter')"
        >
          <div class="flex">
            <img src="@/assets/images/wallet.svg" />
            <h4>{{ $t("Reports.studentsWallet") }}</h4>
          </div>
        </div>

        <div
          v-if="checkPrivilege(hasUserWalletTransactionReport())"
          class="reports-card animate__animated animate__bounceInLeft"
          @click="openBottomSheet('StudentsWalletTransactionsReportFilter')"
        >
          <div class="flex">
            <img src="@/assets/images/userWalletTransactions.svg" />
            <h4>{{ $t("Reports.studentsWalletTransaction") }}</h4>
          </div>
        </div>

        <div
          v-if="checkPrivilege(hasUserOrderReport())"
          class="reports-card animate__animated animate__bounceInRight"
          @click="openBottomSheet('StudentsOrdersReportFilter')"
        >
          <div class="flex">
            <img src="@/assets/images/userOrders.svg" />
            <h4>{{ $t("Reports.studentsOrders") }}</h4>
          </div>
        </div>
      </div>

      <StudentsIdReportFilter v-on:isLoading="isLoading = $event" />
      <StudentsWalletReportFilter v-on:isLoading="isLoading = $event" />
      <StudentsWalletTransactionsReportFilter
        v-on:isLoading="isLoading = $event"
      />
      <StudentsOrdersReportFilter v-on:isLoading="isLoading = $event" />
    </div>
  </div>
</template>

<script>
import DashboardNavbar from "@/layouts/components/DashboardNavbar.vue";
import PreLoader from "@/components/general/PreLoader.vue";

import StudentsIdReportFilter from "./components/StudentsIdReportFilter.vue";
import StudentsWalletReportFilter from "./components/StudentsWalletReportFilter.vue";
import StudentsWalletTransactionsReportFilter from "./components/StudentsWalletTransactionsReportFilter.vue";
import StudentsOrdersReportFilter from "./components/StudentsOrdersReportFilter.vue";

import generalMixin from "@/utils/generalMixin";
import { checkPrivilege } from "@/utils/functions";
import {
  hasStudentsIdReport,
  hasStudentsWalletReport,
  hasUserWalletTransactionReport,
  hasUserOrderReport,
} from "./../..//utils/privilegeHelper";

export default {
  components: {
    DashboardNavbar,
    PreLoader,
    StudentsIdReportFilter,
    StudentsWalletReportFilter,
    StudentsWalletTransactionsReportFilter,
    StudentsOrdersReportFilter,
  },
  mixins: [generalMixin],
  data() {
    return {
      statementStatus: false,
    };
  },
  methods: {
    checkPrivilege,
    hasStudentsIdReport,
    hasStudentsWalletReport,
    hasUserWalletTransactionReport,
    hasUserOrderReport,
  },
  computed: {},
};
</script>
<style lang="css">
.hover {
  cursor: pointer;
}
.flex {
  display: flex;
  align-content: center;
  align-items: center;
}
</style>
